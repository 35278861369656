<template>
  <div>
    <div v-loading.fullscreen.lock="loading">
      <el-row style="padding: 16px; text-align: start">
        <el-col>
          <el-card>
            <template #header>
              <div class="clearfix">
                <a>
                  <h3>{{ $t('Текущие расследования') }}</h3>
                  <!--                                    <el-button type="primary" @click="updateTribuna" style="margin-left: 16px">-->
                  <!--                                        Обновить-->
                  <!--                                    </el-button>-->
                </a>
                <div>
                  <el-autocomplete
                    v-model="selectedCaseName"
                    :fetch-suggestions="filterCasesNames"
                    :placeholder="$t('Текущие расследования')"
                    :popper-append-to-body="false"
                    :disabled="false"
                    trigger-on-focus
                    @select="handleSelectCase"
                  ></el-autocomplete>
                </div>
              </div>
            </template>
            <!--            <div ref="caseInfo" id="caseInfo" v-if="caseDetailsOn"></div>-->
            <preview-case v-if="caseDetailsOn" :case="CaseSelected" />
            <el-tabs type="border-card" @tab-click="showCaseDetails" v-model="activeTabName">
              <el-tab-pane v-for="Case in cases" :key="Case.caseId" :name="Case.caseId" :label="Case.case">
                <div class="table">
                  <transition-group v-if="CaseSelected !==null" name="tribune-table" tag="div" class="tbody">
                    <div v-for="item in CaseSelected.players" :key="item.username" class="tr">
                      <div class="td" scope="row">
                        <div style="text-align: center;width: 100%">
                          <div>
                            <el-avatar :src="item.avatar" :size="80" style="line-height: 90px;">
                            </el-avatar>
                          </div>
                          <div>
                            <div style="margin-top: 8px">
                              <a>
                                <div class="title" style="font-size: 20px">{{ item.username }}</div>
                              </a>
                              <!--                              <a>-->
                              <!--                                <span :class="'flag-icon flag-icon-' + item.country"></span>-->
                              <!--                              </a>-->
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="td" v-if="!showAnswer[item.sessionId]" style="width: 260px">
                        <div style="text-align: center">
                          <el-row
                              v-if="item.allMode"
                              :style="{
                              'margin-bottom': '8px',
                              padding: '4px',
                              'background-color': colorAllCases[namesAllCases.indexOf(item.case)],
                              'border-radius': '12px',
                              color: 'white',
                            }"
                          >
                            <el-col>
                              {{ item.case }}
                            </el-col>
                          </el-row>
                        </div>

                        <el-row>{{ $t('Всего запросов') }}: {{ item.queriesAll }}</el-row>
                        <el-row>{{ $t('Сохранено схем') }}: {{ item.schemesAll }}</el-row>
                        <el-row>{{ $t('Создано элементов') }}: {{ item.allElements }}</el-row>
                        <el-row>{{ $t('Элементов сейчас') }}: {{ item.currentElements }}</el-row>
                        <!--                        <el-row style="margin-top: 8px">-->
                        <!--                          <el-button @click="expandRow(item)">-->
                        <!--                            ЛОГ-->
                        <!--                          </el-button>-->
                        <!--                        </el-row>-->
                      </div>
                      <!-- Не показывем график
                      <div class="td" v-if="!showAnswer[item.sessionId]">
                        <TribuneElement
                            :ref="'chart_' + item.sessionId"
                            :element="item"
                        ></TribuneElement>
                      </div>
                      -->

                      <div class="td" v-if="!showAnswer[item.sessionId]">
                        <el-row style="font-size: 17px">{{ item.currentWindow }}</el-row>
                        <hr style="margin-top: 4px;margin-bottom: 4px" />
                        <el-row
                            v-for="i in item.log.length >= 5 ? 5 : item.log.length"
                            :key="i"
                            :style="{ opacity: 1 - 0.6 * ((i + 1) / 5) }"
                        >
                          {{ item.log[item.log.length - i].name }}
                        </el-row>
                      </div>

                      <div class="td" style="width: 350px">
                        <div>
                          <div style="margin-top: 4px">
                            <div>
                              <div
                                  style="margin-right: 8px;display: inline-block;vertical-align: middle;font-size: 16px;
  font-weight: 800;"
                              >
                                {{ $t('Цели') }}:
                              </div>
                              <div
                                  v-for="(aim, index) in item.aims"
                                  :key="index"
                                  :class="aim === 1 ? 'aim-done' : 'aim'"
                              ></div>
                            </div>
                          </div>
                          <div
                              style="margin-top: 8px;font-size: 16px;
  font-weight: 800;"
                          >
                            {{ $t('В избранном') }}: {{ item.inCart }}
                          </div>
                        </div>
                        <hr
                            v-if="item.finishTime !== 0"
                            style="margin-top: 8px;margin-bottom: 16px"
                        />
                        <div v-if="item.finishTime !== 0">
                          <div style="margin-top: 4px">
                            <div>
                              <div
                                  style="margin-right: 8px;display: inline-block;vertical-align: middle;font-size: 16px;
  font-weight: 800;"
                              >
                                {{ $t('Ответ') }}:
                              </div>
                              <div
                                  v-for="(aim, index) in item.answer"
                                  :key="index"
                                  :style="{
                                  height: '25px',
                                  width: '25px',
                                  'vertical-align': 'middle',
                                  background:
                                    aim > 0
                                      ? `linear-gradient(to top, #AC3B61, #AC3B61 ${Math.floor(
                                          aim * 100,
                                        ) - 1}%, #ccc ${Math.floor(aim * 100)}%)`
                                      : '#ccc',
                                  'border-radius': '50%',
                                  display: 'inline-block',
                                  'margin-left': '8px',
                                  'margin-bottom': '4px',
                                }"
                              ></div>
                            </div>
                          </div>
                          <div
                              style="margin-top: 16px;font-size: 22px;
  font-weight: 800;"
                          >
                            {{ $t('Время') }}: {{ crateTimeStr(item.finishTime) }}
                          </div>
                        </div>
                      </div>

                      <div class="td" v-if="!showAnswer[item.sessionId]">
                        <el-button
                            :disabled="item.finished ? false : true"
                            @click="showAnswer[item.sessionId] = true"
                        >{{ $t('Ответ') }}</el-button
                        >
                      </div>

                      <div class="td" v-if="showAnswer[item.sessionId]">
                        <vue-json-pretty :data="item.realanswer" :deep="3" :showLength="true">
                        </vue-json-pretty>
                      </div>

                      <div class="td" v-if="showAnswer[item.sessionId]">
                        <el-button
                            :disabled="item.finished ? false : true"
                            @click="showAnswer[item.sessionId] = false"
                        >{{ $t('Назад') }}</el-button
                        >
                      </div>
                    </div>
                  </transition-group>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
//import TribuneElement from './TribuneElement';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import CaseSessionController from '../../controllers/caseSession.controller';
import previewCase from '../Cases/previewCase';
import OntologyController from '../../controllers/ontology.controller';

export default {
  name: 'Tribune',
  components: {
    //TribuneElement,
    previewCase,
    VueJsonPretty,
  },
  data() {
    return {
      activeTabName: '',
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - 150,
      sessionController: null,
      data: [],
      showAnswer: {},
      ontology: null,
      controller: null,
      ontologyController: null,
      loading: false,
      page: 1,
      start: 0,
      end: 0,
      perPage: 20,
      totalItems: 0,
      caseDetailsOn: false,
      cases: [],
      CaseSelected: null,
      selectedCaseName: null,
      logInterval: null,
      colorAllCases: ['#3c84c5', '#a050d2', '#3daa5f', '#ca4545', '#dea636', '#36c3b3'],
      namesAllCases: [],
      queriesImg: {
        addScheme: 'fas fa-plus',
        changeScheme: 'f+' + 'as fa-sync',
        deleteScheme: 'fas fa-times',
        addCartElement: 'fas fa-plus',
        deleteCartElement: 'fas fa-times',
        addNode: 'fas fa-plus',
        addLink: 'fas fa-plus',
        deleteElement: 'fas fa-times',
        deleteGraphElement: 'fas fa-times',
        answer: 'fas fa-check',
        expandNode: 'fas fa-arrows-alt',
        queryDone: 'fas fa-eye',
        showEdges: 'fas fa-grip-lines',
        visScheme: 'fas fa-play',
        getSubgraphGraph: 'fas fa-share-alt',
        getSubgraphMap: 'fas fa-globe',
        // "getVGraph": require("../assets/queries/vers_03-04.svg"),
        searchNodeByString: 'fas fa-search',
        searchSubgraphByString: 'fas fa-list',
      },
    };
  },
  computed: {
    paginateData() {
      if (this.page * this.perPage < this.totalItems)
        return this.data.slice((this.page - 1) * this.perPage, this.page * this.perPage);
      return this.data.slice((this.page - 1) * this.perPage, this.length);
    },
  },
  mounted() {
    this.loading = true;
    this.ontologyController = new OntologyController();
    this.ontology = this.ontologyController.getOntology();
    this.page = 1;
    this.sessionController = new CaseSessionController();
    // Получение названий и id кейсов и подгружаем первую вкладку если есть хотя бы один кейс
    this.getCases().then(() => {
      if (this.cases[0] !== undefined) {
        this.activeTabName = this.cases[0].caseId; // Чтобы была "нажата" первая вкладка
        this.getDetailCase(this.cases[0].caseId).finally(() => (this.loading = false));
      }
    });

    // Обновление данных каждую минуту
    if (this.logInterval !== null) clearInterval(this.logInterval);
      this.logInterval = setInterval(() => {
        this.getCases();
      }, 60000);
  },

  beforeUnmount: function() {
    // При уходе с страницы остановка отправки запроса
    clearInterval(this.logInterval);
  },
  methods: {
    crateTimeStr(sec_num) {
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours > 0) {
        //if (hours   < 10) hours   = "0"+hours
        hours += 'ч ';
      } else hours = '';
      if (minutes > 0) {
        //if (minutes   < 10) minutes   = "0"+minutes
        minutes += 'мин ';
      } else minutes = '';
      //if (seconds < 10) seconds = "0"+seconds
      seconds += 'сек';
      return hours + minutes + seconds;
    },
    showCaseDetails(tab) {
      this.loading = true;
      this.caseDetailsOn = !this.caseDetailsOn
      this.getDetailCase(tab.props.name).finally(() => (this.loading = false));
    },
    expandRow(row) {
      row.expand = !row.expand;
      this.$refs['tribuneTable' + row.case].toggleRowExpansion(row, row.expand);
    },
    updateTribuna() {
      const vm = this;
      return this.sessionController.getTribuna().then((response) => {
        let newData = response.data;

        // let p = Math.random();
        // console.log(p, newData[0].players[0]);
        // if (p > 0.3) {
        //   newData[0].players = newData[0].players.reverse()
        //   if (Math.random() > 0.5) {
        //     newData[0].players = newData[0].players.reverse()
        //   }
        // }

        vm.data = newData;
        // if (vm.CaseSelected === null) vm.CaseSelected = newData[0]
        // let refreshData = false
        // if (newData.length !== vm.data.length) refreshData = true
        // if (!refreshData) {
        //   console.log('not refreshData')
        //   for (let n = 0; n < newData.length; n++) {
        //     let Case = newData[n]
        //     console.log('Case', Case)
        //     if (Case.caseId === vm.CaseSelected.caseId) {
        //       if (Case.players.length !== vm.CaseSelected.players.length) refreshData = true
        //       vm.CaseSelected = Case
        //     }
        //     if (!refreshData) {
        //       let indexN = 0
        //       for (let i = 0; i < vm.data.length; i++) {
        //         if (vm.data[i].caseId === vm.CaseSelected.caseId) indexN = i
        //       }
        //       console.log('indexN', indexN)
        //       for (let i = 0; i < newData[n].players.length; i++) {
        //         for (let j = 0; j < vm.data[indexN].players.length; j++) {
        //           if (vm.data[indexN].players[j].sessionId === newData[n].players[i].sessionId) {
        //             let session = newData[n].players[i]
        //             vm.data[indexN].players[j].sessionId = session.sessionId
        //             vm.data[indexN].players[j].queriesAll = session.queriesAll
        //             vm.data[indexN].players[j].username = session.username
        //             vm.data[indexN].players[j].case = session.case
        //             vm.data[indexN].players[j].avatar = session.avatar
        //             vm.data[indexN].players[j].Answer = session.Answer
        //             vm.data[indexN].players[j].country = session.country
        //             vm.data[indexN].players[j].currentWindow = session.currentWindow
        //             vm.data[indexN].players[j].schemesAll = session.schemesAll
        //             vm.data[indexN].players[j].currentElements = session.currentElements
        //             vm.data[indexN].players[j].allElements = session.allElements
        //             vm.data[indexN].players[j].inCart = session.inCart
        //             vm.data[indexN].players[j].aims = session.aims
        //             vm.data[indexN].players[j].log = session.log
        //             vm.data[indexN].players[j].activity = session.activity
        //             vm.data[indexN].players[j].startTime = session.startTime
        //             vm.data[indexN].players[j].finishTime = session.finishTime
        //             break
        //           }
        //         }
        //
        //       }
        //     } else vm.data = newData
        //   }
        // } else vm.data = newData
        // console.log('p', vm.data)

        for (let Case of vm.data) {
          if (Case.case != 'Все') {
            if (vm.namesAllCases.indexOf(Case.case) < 0) vm.namesAllCases.push(Case.case);
          }
          for (let player of Case.players) {
            if (!(player.sessionId in vm.showAnswer)) {
              vm.showAnswer[player.sessionId] = false;
            }

            //vm.$nextTick(() => {
            //  console.log('vm.$refs', vm.$refs);
            //  vm.$refs['chart_' + player.sessionId].restart(player.activity, player.startTime);
            //});
          }
        }
      });
    },

    getCases() {
      return this.sessionController.getCases().then((response) => {
        this.cases = response.data;
      });
    },

    getDetailCase(caseId) {
      return this.sessionController.getCaseById(caseId).then((response) => {
        this.CaseSelected = response.data;
        this.caseDetailsOn = !this.caseDetailsOn;

        if (this.CaseSelected.case != 'Все') {
          if (this.namesAllCases.indexOf(this.CaseSelected.case) < 0) this.namesAllCases.push(this.CaseSelected.case);
        }
        for (let player of this.CaseSelected.players) {
          if (!(player.sessionId in this.showAnswer)) {
            this.showAnswer[player.sessionId] = false;
          }

          //this.$nextTick(() => {
          //  console.log('vm.$refs', this.$refs);
          //  this.$refs['chart_' + player.sessionId].restart(player.activity, player.startTime);
          //});
        }
      });
    },

    pageChange(page) {
      this.page = page;
    },

    handleSelectCase(item) {
      this.activeTabName = item.tag;
      this.loading = true;
      this.caseDetailsOn = !this.caseDetailsOn
      this.getDetailCase(item.tag).finally(() => (this.loading = false));
    },

    filterCasesNames(searchString, cb) {
      if ((searchString !== null) && (searchString !== '')) {
        this.loadingCascade = true

        setTimeout(() => {
          const vm = this;
          let searchList = []
          let upperSearchString = searchString.toUpperCase();
          for (let Case of vm.cases) {
            if (Case.case != 'Все') {
              if (vm.namesAllCases.indexOf(Case.case) < 0) vm.namesAllCases.push(Case.case);
              if (Case.case.toUpperCase().indexOf(upperSearchString) >= 0) {
                searchList.push({'value': Case.case, 'tag': Case.caseId});
              }
            }
          }
          searchList.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
          cb(searchList);
        }, 500);
      } else {
        setTimeout(() => {
          const vm = this;
          let searchList = []
          for (let Case of vm.cases) {
            if (Case.case != 'Все') {
              if (vm.namesAllCases.indexOf(Case.case) < 0) vm.namesAllCases.push(Case.case);
              searchList.push({'value': Case.case, 'tag': Case.caseId});
            }
          }
          searchList.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
          cb(searchList);
        }, 500);
      }

    },
  },
};
</script>

<style scoped>
>>> .el-transfer-panel {
  width: 300px;
}

>>> thead {
  display: none;
}

>>> .el-table__expand-column {
  opacity: 0;
}

.aim {
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 4px;
}

.aim-done {
  height: 25px;
  width: 25px;
  vertical-align: middle;
  background-color: #123c69;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 4px;
}

.data-grid {
  width: 98%;
  margin: 10px auto;
  padding: 2px;
  background-color: white;
  border: 2px solid #3f51b5;
  overflow: hidden;
}
.table {
  display: table;
  width: 100%;
  font-size: 13px;
  font-family: Arial, sans-serif;
  color: #263238;
  background-color: #efefef;
  cursor: pointer;
  border-collapse: separate;
  border-spacing: 0 2px;
}
.tbody {
  display: table-row-group;
}
.tr {
  display: table-row;
  margin-bottom: 12px;
}
.td {
  font-size: 16px;
  font-weight: 800;
  font-family: Helvetica;
  letter-spacing: 0.06em;
  background-color: white;
  display: table-cell;
  position: relative;
}
.tr .td:not(:last-child) {
  border-right: 1px solid #efefef;
}
.tbody .td {
  vertical-align: middle;
  color: #263238;
  text-align: start;
  padding: 4px 16px;
}

.tribune-table-move {
  transition: transform 1s;
}
</style>
